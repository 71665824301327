$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";


html, body, #map {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

#map {
  visibility: hidden;
}

table {
  border-collapse: collapse;
}

table tbody, table td, table td * {
  vertical-align: top;
  padding-left: 0px;
}

table tr td:nth-child(2) {
  padding-left: 5px;
}

input[type=radio] {
  transform:scale(0.8);
}

input[type=checkbox] {
  transform:scale(0.8);
}

.loader,
.loader:before,
.loader:after {
  background: #000;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader {
  color: #000;
  text-indent: -9999em;
  position: absolute;
  left: 50vw;
  top: 45vh;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.leaflet-control-layers label {
  font-size: 0.75rem;
}

.leaflet-control-attribution svg {
  display: none !important;
}

.leaflet-popup-content {
  font-size: 1em;
}

.leaflet-popup-content p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.leaflet-popup-content h3, h4, h5 {
  margin-top: 0.5em;
  margin-bottom: 0.2em;
}

.leaflet-sidebar-pane h3, h4, h5 {
  margin-top: 0.5rem;
  margin-bottom: 0.2em;
}

.leaflet-sidebar-pane h5 {
  font-size: 0.75rem;
}

.leaflet-sidebar-pane p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0px;
  margin-right: 0px;
}

.leaflet-sidebar-pane img {
  max-width: 100%;
  height: auto;
  padding-top: 10px;
}

.leaflet-sidebar-pane ul { 
  padding-left: 1em;
}

.leaflet-routing-container {
  width: 300px;
}

.leaflet-routing-container-hide .leaflet-routing-collapse-btn {
  left: 2px;
  top: 3px;
}

img.legende {
  padding-top: 0px;
}

@media (max-width: 767px) {
  .leaflet-left .leaflet-control {
    margin-left: 0px;
  }
}
  
